@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@500&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Lato:wght@300;400;700;900&display=swap);
/* @import "~mdb-ui-kit/css/mdb.min.css"; */
/* BLOG BANNER AREA */

.blog h1,
.blog h2,
.blog h3,
.blog h4,
.blog h5,
.blog h6,
.blog p,
.blog a {
    font-family: "Lato", sans-serif;
}

.blog h1 {
    color: #fff;
}

@media (max-width: 450px) {
    .blog h1 {
        font-size: 24px;
    }
}

.blog .blog-grid h1 {
    color: #000e22;
}

.blog .landing {
    /* background: url("../../assets/images/blog/25544.jpg") center center fixed
        no-repeat;
    background-size: cover; */
    /* filter: drop-shadow(0px 5px 30px #111) brightness(0.4) contrast(85%); */
    background: #2c2e37; /* fallback for old browsers */
    background: -webkit-linear-gradient(
        to top,
        #2c2e37,
        #bdc3c7
    ); /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(
        to bottom,
        #2c2e37,
        #bdc3c7
    ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}

.blog .landing::after {
    position: relative;
    width: 100%;
    height: 130vh;
    background: rgba(57, 67, 89, 0.8);
    z-index: 1;
}

.blog .carousel {
    /* why */
    height: 130vh;
    /* position: inherit !important; */
}

@media (max-width: 450px) {
    .blog-content {
        padding-top: 10px !important;
    }

    .blog .carousel {
        /* why */
        height: 100vh;
        /* position: inherit !important; */
    }

    .blog .landing .featured-image {
        text-align: center;
    }

    .blog .landing .featured-image img {
        max-width: 80vw;
    }
}

@media (min-width: 768px) and (max-width: 992px) {
    .blog .landing .featured-image {
        text-align: center;
    }

    .blog .landing .featured-image img {
        max-width: 50vw;
    }
}

@media (min-width: 991px) {
    .blog .carousel {
        height: 100vh;
    }
    .blog .landing::after {
        height: 100vh;
    }
}

.carousel__dot-group {
    /* position: absolute;
    bottom: 12vh; */
}

.carousel-navigation {
    position: absolute;
    bottom: 0vh;
    left: 0;
    right: 0;
}

@media (max-width: 450px) {
    .carousel-navigation {
        position: relative;
    }
}

.carousel__dot-group .carousel__dot {
    height: 12px;
    width: 12px;
    margin: 7px;
    padding: 3px;
    transform: translateY(2px);
    border-radius: 50%;
    border: none;
    background-color: #f9f9f9;
}

@media (max-width: 450px) {
    .carousel__dot-group .carousel__dot {
        height: 4px;
        width: 4px;
        margin: 4px;
        padding: 3px;
    }
}

.carousel__dot--selected {
    background-color: salmon !important;
}

.carousel__next-button {
    border-radius: 100%;
    width: 40px;
    height: 40px;
    text-align: center;
    background-color: transparent;
    color: #898989;
    /* border: 2px solid #898989; */
    border: none;
}

.carousel__back-button {
    border-radius: 100%;
    width: 40px;
    height: 40px;
    text-align: center;
    background-color: transparent;
    color: #898989;
    /* border: 2px solid #898989; */
    border: none;
}

@media (max-width: 450px) {
    .carousel__next-button,
    .carousel__back-button {
        width: 40px;
        height: 40px;
    }
}

.carousel__next-button:hover,
.carousel__back-button:hover {
    /* color: salmon;
    border: 2px solid salmon; */
    color: #a9a9a9;
    border: 2px solid #a9a9a9;
}

.carousel__next-button:active,
.carousel__back-button:active {
    /* color: salmon;
    border: 2px solid salmon; */
    color: #fff;
    border: 2px solid #fff;
}

.carousel__next-button:focus,
.carousel__back-button:focus {
    outline: none;
}

.meta-desc {
    color: #c9c9c9;
}

.landing .post-author {
    color: #fff;
}

.landing .post-date {
    color: #fff;
}

.slick-dots {
    bottom: 70px;
}

.slick-dots li button::before {
    color: #fff;
    font-size: 15px;
}

.slide-container {
    height: 130vh;
    /* background-color: #dadada; */
    /* border-bottom: 80px outset #898989; */
}

@media (max-width: 450px) {
    .slide-container {
        height: 100vh;
    }
}

.slide-container .post-title {
    color: #fff;
    z-index: 2;
    transition: 250ms;
}

.slide-container .post-title:hover {
    color: #c9c9c9;
}

/* BLOG POST PREVIEW BOX */
.post-preview h2 a {
    font-size: 25px;
    color: #000e22;
}

.post-preview h2 a:hover {
    color: slategrey;
}
.post-preview .post-thumbnail {
    flex: 0 0 100% !important;
    margin-bottom: 30px;
}

.post-preview a img {
    border-radius: 7px;
    /* min-height: 200px; */
}

.post-preview .post-title {
    color: #393939;
    font-size: 20px;
    transition: 250ms;
}

.post-preview .post-title:hover {
    color: slateblue;
}

.post-preview .post-author a {
    color: #494949;
}

.post-meta .post-category {
    background-color: #0078ff;
    font-size: 13px;
    text-transform: uppercase;
    color: #fff;
    border-radius: 3px;
}

.slide-container .post-meta .post-category {
    background-color: #797979;
    font-size: 16px;
    text-transform: uppercase;
    color: #fff;
    border-radius: 3px;
    z-index: 2;
    padding: 7px 20px !important;
}

.slide-container .post-meta .post-date {
    font-size: 24px;
}

@media (max-width: 450px) {
    .slide-container .post-meta {
        padding-bottom: 5px !important;
    }

    .slide-container .post-meta .post-date {
        font-size: 20px;
    }
}

.blog-navbar-container {
    /* background: #323542bc; */
}

.blog-navbar {
    box-shadow: none !important;
    background: transparent !important;
    padding-top: 12vh !important;
}

.dropdown-hover:hover > .dropdown-menu {
    display: inline-block;
}

.dropdown-hover > .dropdown-toggle:active {
    pointer-events: none;
}

/* .blog-sidebar-label .nav-link {
    color: #fff;
} */

.blog-nav-link:hover {
    color: coral;
}

.blog-navbar .navbar-toggler {
    border: none;
}

.blog-navbar .navbar-toggler:active {
    outline: none !important;
}

.blog-navbar .navbar-nav {
    padding: 25px 20px;
    background: rgba(57, 67, 89, 0.4);
}
.blog-navbar .nav-link {
    color: #fff !important;
    /* border: 2px solid #fff; */
    height: 45px;
}

.blog-navbar .dropdown-menu {
    background: rgba(255, 255, 255, 0.1);
}

.blog-navbar .dropdown-menu a {
    height: 45px;
}

.category-header-nav {
    color: #000e22;
}
