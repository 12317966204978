@import url(https://fonts.googleapis.com/css2?family=Lato:wght@300;400;700;900&display=swap);

.blog-post h1,
.blog-post h2,
.blog-post h3,
.blog-post h4,
.blog-post h5,
.blog-post h6,
.blog-post p,
.blog-post a {
    font-family: "Lato", sans-serif;
}

.blog-post h1 {
    font-size: 46px;
}

/* Blog Post Header */

.blog-post .post-header {
    background: #fff;
    height: auto;
    background-size: cover;
    background-attachment: fixed;
}

.blog-post .post-header::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 50vh;
    /* background: rgba(57, 67, 89, 0.85); */
    opacity: 0.9;
    z-index: 1;
}

/* .blog-post .post-header > * {
    z-index: 100;
} */

.post-header .post-title {
    color: #000e22;
    z-index: 3;
    padding: 0 10vw;
}

@media (max-width: 768px) {
    .post-header .post-title {
        padding: 0 2vw;
    }
}

@media (min-width: 768px) {
    .post-header .post-title {
        padding: 0 3vw;
    }
}

.post-header .post-title h1 {
    font-size: 35px;
}

@media (max-width: 450px) {
    .post-header .post-title h1 {
        font-size: 26px;
    }
}

.post-header .post-meta .post-category {
    background-color: #797979;
    font-size: 16px;
    text-transform: uppercase;
    color: #fff;
    border-radius: 3px;
    z-index: 2;
    padding: 7px 20px !important;
}

.post-header ul {
    list-style: none;
    margin: 0;
    padding: 0;
    margin-top: 2vh;
}

.post-header ul li {
    display: inline-block;
    font-size: 22px;
}

.post-header ul li::after {
    content: "•";
    display: inline-block;
    padding-left: 5px;
    padding-right: 5px;
}

.post-header ul li a {
    color: #000;
    font-weight: 700;
    text-decoration: none;
}

@media (max-width: 768px) {
    .blog-post section {
        padding: 0 0 !important;
    }
}

.blog-post .featured-image {
    /* max-width: 65vw; */
}

.post-content .featured-image {
    margin-bottom: 7vh;
}

.post-content .post-body-wrapper {
    text-align: center;
}

.post-content .post-body {
    /* display: inline-block; */
    text-align: left;
    padding: 0 10vw;
}

@media (max-width: 768px) {
    .post-content .post-body {
        padding: 0 2vw;
    }
}

@media (min-width: 768px) {
    .post-content .post-body {
        padding: 0 3vw;
    }
}

/* post content */
.post-body p {
    font-size: 20px;
}

.post-body a {
    color: blue;
}

.breadcrumb a {
    color: #a9a9a9;
    font-weight: 700;
    font-size: 18px;
}

.breadcrumb .title {
    font-weight: 700;
}

.loading-animation {
    max-width: 5vw;
}
